<template>
  <div class="works-detail">
    <van-nav-bar @click-left="$router.push('/topic')" @click-right="onClickRight">
      <template #title>详情</template>
      <template #left>
        <span class="iconfont ico">&#xe60b;</span>
      </template>
      <template #right>
        <span class="iconfont ico">&#xe684;</span>
      </template>
    </van-nav-bar>
    <div class="works-info">
      <!-- <div class="works-title">江城子·西城杨柳弄春柔</div> -->
      <div class="works-img"><img :src="worksdetail.url" alt="" /></div>
      <div class="works-author">
        <p>
          <span>{{ worksdetail.num }}号</span>{{ worksdetail.author }}
        </p>
        <p v-if="worksdetail.isshow">
          <span class="s1">排名</span><span class="s2">{{ worksdetail.rank }}</span> <span class="s1">票数</span><span class="s2">{{ worksdetail.votes }}</span>
        </p>
      </div>
      <div class="line"></div>
      <div class="works-desc">作品描述</div>
      <div class="works-content">
        {{ worksdetail.content }}
      </div>
    </div>
    <div class="works-vote">
      <router-link tag="button" to="/download">为TA投票</router-link>
    </div>
    <!-- 弹出层 -->
    <van-share-sheet @select="selectTag" v-model="showShare" :options="options" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      worksdetail: {},
      // 分享
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' },
        ]
      ]
    }
  },
  props: ['id'],
  created() {
    this.querywordsD()
  },
  methods: {
    async querywordsD() {
      let { data: res } = await this.$api.querywordsD(this.id)
      if (res.code !== 200) return this.$toast.fail(res.msg)
      this.worksdetail = res.data
    },
    // 分享
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone()
      } else if (tag.name === '微博') {
        this.weiShare()
      } else {
        this.$toast({
          type: 'html',
          message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">篇文章分享出去</p>'
        })
      }
      this.showShare = false
    },
    // qq分享
    qzone() {
      let url = location.href;
      var shareqqzonestring =
        "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
        url +
        "&title=" +
        '' +
        "&desc=" +
        "【分享来之凯迪网@凯迪网】" +
        "&summary=&site=&pics=";
      window.open(shareqqzonestring);
      window.open(
        shareqqzonestring,
        "newwindow",
        "height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
      //此处分享链接内无法携带图片
      // var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      // var url = 'https://tmobile.9kd.com/note/24736'
      // var param = {
      //   url: url || window.location.href,
      //   desc: '天天', /*分享理由*/
      //   title: '哈哈哈' || '', /*分享标题(可选)*/
      //   summary: 'xx',/*分享描述(可选)*/
      //   pics: pic || '',/*分享图片(可选)*/
      //   flash: '', /*视频地址(可选)*/
      //   site: '' /*分享来源 (可选) */
      // };
      // var s = [];
      // for (var i in param) {
      //   s.push(i + '=' + encodeURIComponent(param[i] || ''));
      // }
      // var targetUrl = "http://connect.qq.com/widget/shareqq/iframe_index.html?" + s.join('&');
      // window.open(targetUrl);
    },
    weiShare() {
      // let url = location.href;
      // let url = 'https://3g.163.com/dy/article/FQ3EH4ED0514BE2Q.html?clickfrom=index2018_news_newslist&s=163&w=1&f=wb#offset=4';
      // var sharesinastring =
      //   "http://v.t.sina.com.cn/share/share.php?title=" +
      //   "凯迪网" + this.noteInfo.title +
      //   "&url=" +
      //   url +
      //   "&content=utf-8&sourceUrl=" +
      //   url +
      //   "&pic=" +
      //   "";
      // window.open(
      //   sharesinastring,
      //   "newwindow",
      //   "height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      // );
      var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      var url = 'https://9kd.com/'


      const share = {
        title: '分享标题',
        image_url: pic,
        share_url: url
      };
      window.open('https://service.weibo.com/share/share.php?url=' + encodeURIComponent(share.share_url) + '&title=' + share.title + '&appkey=4129988944&&ralateUid=1744259092&pic=' + share.image_url + '&searchPic=true');

      return

      url = encodeURIComponent(url)
      var param = {
        url: url || window.location.href,
        type: '3',
        count: '1', /** 是否显示分享数，1显示(可选)*/
        appkey: '4129988944', /** 您申请的应用appkey,显示分享来源(可选)*/
        title: '你好微博。。。', /** 分享的文字内容(可选，默认为所在页面的title)*/
        pic: pic || '', /**分享图片的路径(可选)*/
        ralateUid: '', /**关联用户的UID，分享微博会@该用户(可选)*/
        rnd: new Date().valueOf()
      }
      var temp = [];
      for (var p in param) {
        temp.push(p + '=' + encodeURIComponent(param[p] || ''))
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400');
    },
    onClickRight() {
      this.showShare = true
    },
  },
}
</script>

<style lang="less">
.works-detail {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #3a3e45;
  letter-spacing: 1px;
  margin-bottom: 90px;
  font-size: 15px;
  .van-nav-bar {
    height: 44px;
    background: #c48657;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
  .ico {
    color: #ffffff;
    font-size: 25px;
  }
  .works-info {
    padding: 16px;
    .works-title {
      font-size: 20px;
    }
  }
  .works-img {
    margin-top: 14px;
    img {
      width: 100%;
    }
  }
  .works-author {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 19px;
      color: #000000;
      font-weight: bold;
    }
    .s1 {
      color: #9a9a9a;
      font-size: 12px;
      margin-left: 23px;
      margin-right: 5px;
    }
    .s2 {
      color: #ff351a;
      font-size: 19px;
      font-weight: bold;
    }
  }
  .line {
    width: 338px;
    border-top: 1px solid #eeeeee;
  }
  .works-desc {
    font-size: 17px;
    line-height: 50px;
    color: #000000;
  }
  .works-content {
    line-height: 30px;
  }
  .works-vote {
    button {
      width: 282px;
      height: 44px;
      display: block;
      margin: 0 auto;
      background: url('https://cdn.9kd.com/kdnet/%E4%B8%BAta%E6%8A%95%E7%A5%A8%403x.png') center center no-repeat;
      background-size: 100% 100%;
      border-radius: 22px;
      border: none;
      color: #ffffff;
    }
  }
}
</style>
